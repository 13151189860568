<template>
  <modal
    v-if="assignment && assignment.asset"
    name="asset-changes-modal"
    height="auto"
    :scrollable="false"
    class="vue-modal"
    width="75%"
    @before-open="beforeOpen"
    @closed="resetData"
  >
    <div class="vue-modal-inner" v-if="assetId && localChangeRequest">
      <div class="vue-modal-topbar">
        <h4>New Draft for Asset #{{ assignment.asset.id }}</h4>
      </div>
      <div class="modal-container change-request-modal">
        <div class="asset-container">
          <div class="asset-row">
            <div class="asset-section">
              <label for="">Current</label>
              <div class="asset-review">
                <div :class="{'editor-padding': wysiwyg }"></div>
                <div v-html="original" class="review-copy" />
              </div>
            </div>
            <div class="asset-section">
              <label for="">Changes</label>
              <div class="active-changes">
                <tinymce-editor
                  v-if="wysiwyg"
                  ref="asset-question-editor"
                  v-model="localChangeRequest.requested_change"
                  :init="tinymceConfig({plugins: ['code', 'image', 'table', 'lists', 'autoresize'], autoresize_bottom_margin: 10}, tinymceFullConfig)"
                  use-custom-image-handler
                  @onInit="setCaretPosition"
                  @onChange='modified'
                />
                <textarea
                  v-else
                  v-model="localChangeRequest.requested_change"
                  @change='modified'
                />
              </div>
            </div>
          </div>
          <div class="asset-row">
            <div class="asset-section">
              <label for="note">Draft Notes</label>
              <div v-if="currentUser.permissions.is_a_copy_writer">
                <textarea type="text" name="notes" rows="6" v-model="localChangeRequest.reason_for_change"/>
              </div>
              <div v-else>
                <div class="draft-notes-text" v-html="localChangeRequest.reason_for_change" />
              </div>
            </div>
          </div>
          <div class="asset-row">
            <div class="asset-section" v-if="showRejectionNotes">
              <label for="note">Rejection Notes</label>
              <div v-if="currentUser.permissions.is_a_copy_writer">
                <div class="draft-notes-text" v-html="localChangeRequest.rejection_notes" />
              </div>
              <div v-else>
                <textarea type="text" name="notes" rows="6" v-model="localChangeRequest.rejection_notes"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vue-modal-bottombar modal-footer" v-if="assetId">
      <div class="footer-actions">
        <div>
          <div class="state-selection-section" v-if="showState">
            <select v-model="localChangeRequest.state" class="hlt-select state-selection">
              <option value="pending">Pending</option>
              <option value="approved">Approved</option>
              <option value="rejected">Rejected</option>
            </select>
          </div>

        </div>
        <div class="action-buttons">
          <button
            class="button cancel-button modal-button"
            @click="cancel()"
          >
            Cancel
          </button>
          <button
            class="button solid-green-button modal-button"
            @click="saveAssetChanges()"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import TinymceEditor from '@tinymce/tinymce-vue'
import tinymce from '../../../mixins/tinymce.js'
export default {
  components: {
    TinymceEditor
  },
  mixins: [tinymce],
  data () {
    return {
      assetId: null,
      assetType: null,
      context: null,
      original: null,
      wysiwyg: null,
      localChangeRequest: null
    }
  },
  computed: {
    ...mapGetters([
      'assignment',
      'currentUser',
      'changeRequest'
    ]),
    showRejectionNotes () {
      return this.changeRequest(this.assetId, this.assetType, this.context).rejection_notes || this.currentUser.permissions.is_a_copy_editor
    },
    showState () {
      return this.currentUser.permissions.is_a_copy_editor
    }
  },
  methods: {
    ...mapMutations([
      'createChangeRequest',
      'setChangeRequestState'
    ]),
    cancel () {
      this.$modal.hide('asset-changes-modal')
      this.resetData()
    },
    modified () {
      if (this.assignment.state !== 'completed') {
        if (!this.currentUser.permissions.is_a_asset_assigner) {
          this.setChangeRequestState({
            id: this.assetId,
            type: this.assetType,
            column: this.context,
            changeRequestState: 'modified'
          })
        }
      }
    },
    resetData () {
      this.assetId = null
      this.assetType = null
      this.context = null
      this.original = null
      this.wysiwyg = null
      this.localChangeRequest = null
    },
    saveAssetChanges () {
      this.$modal.hide('asset-changes-modal')
    },
    beforeOpen (event) {
      if (event && event.params) {
        if (event.params.assetId) {
          this.assetId = event.params.assetId
        }

        if (event.params.assetType) {
          this.assetType = event.params.assetType
        }

        if (event.params.context) {
          this.context = event.params.context
        }
        if (event.params.original) {
          this.original = event.params.original
        }
        if (event.params.wysiwyg) {
          this.wysiwyg = event.params.wysiwyg
        }
      }
      let cr = this.changeRequest(this.assetId, this.assetType, this.context)
      if (!cr) {
        this.createChangeRequest({
          asset_id: this.assetId,
          asset_type: this.assetType,
          asset_column: this.context,
          state: 'assigned',
          requested_change: this.original
        })
        cr = this.changeRequest(this.assetId, this.assetType, this.context)
      }
      this.localChangeRequest = cr
    }
  }
}
</script>

<style lang="scss" scoped>
.change-request-modal {
  overflow-y: scroll;
  max-height: 70vh;
}
.asset-container {
  padding: 0 1rem;
  .asset-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 20px;
    .asset-section {
      flex: 1;
      .editor-padding {
        height: 65px;
        background: $space-gray-30;
      }
      .review-copy {
        padding: 5px;
      }
      .asset-review {
        border: 1px solid $space-gray-30;
        margin-right: 10px;
      }
      .active-changes {
        display: flex;
        border: 1px solid $sky-blue;
        textarea {
          margin: 0px;
          min-height: 150px;
        }
      }
    }
  }
}
.modal-footer {
  background: $space-gray-30;
  padding: 15px 30px;
  display: flex;
  .footer-actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .action-buttons {
      button {
        padding: 6px 24px !important;
      }
      .cancel-button {
        background: $space-gray;
        border: 1px solid $slate-gray;
        margin-right: 30px;
      }

    }

  }
}
</style>
