<template>
  <modal
    name="confirmation-modal"
    height="auto"
    :scrollable="false"
    class="vue-modal"
    width="45%"
    @before-open="beforeOpen"
    @before-close="resetData"
  >
    <div
      v-if="asset && asset.id != undefined"
      :class="[confirmationStyling, 'vue-modal-inner']"
    >
      <div class="vue-modal-topbar">
        <h4 class="confirmation-modal-title">{{ title }}</h4>
      </div>
      <div class="modal-container">
        <div class="confirmation-modal-name">
          {{ name }}
        </div>
        <div class="confirmation-modal-body" v-html="body" />
      </div>

      <div class="vue-modal-bottombar modal-buttons">
        <button
          class="hlt-button secondary delete-button modal-button confirm-button"
          @click="confirm()"
        >
          Confirm
        </button>
        <button
          class="hlt-button default modal-button"
          @click="cancel()"
        >
          Cancel
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ConfirmationModal',
  data () {
    return {
      title: '',
      body: '',
      name: '',
      confirmationName: '',
      confirmationStyling: ''
    }
  },
  computed: {
    ...mapGetters([
      'asset',
      'assetType'
    ])
  },
  methods: {
    confirm () {
      this.$emit('confirmed', { confirmationName: this.confirmationName })
      if (this.$modal) {
        this.$modal.hide('confirmation-modal')
      }
    },
    cancel () {
      this.$modal.hide('confirmation-modal')
    },
    resetData (event) {
      this.title = ''
      this.name = ''
      this.body = ''
      this.confirmationName = ''
      this.confirmationStyling = ''
    },
    beforeOpen (event) {
      if (event && event.params) {
        if (event.params.title) {
          this.title = event.params.title
        }

        if (event.params.name) {
          this.name = event.params.name
        }

        if (event.params.body) {
          this.body = event.params.body
        }

        if (event.params.confirmationName) {
          this.confirmationName = event.params.confirmationName
        }

        if (event.params.confirmationStyling) {
          this.confirmationStyling = event.params.confirmationStyling
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v--model {
  z-index: 999;
}
.modal-buttons button {
  margin-left: 10px;
}
</style>
