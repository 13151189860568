<template>
  <div class="asset-assignment-container">
    <top-nav />

    <div class="toggle-container" v-if="asset.type == 'MultipleChoice'">
      <preview-toggle @edit="mode = 'edit'" @preview="mode = 'preview'" />
    </div>

    <div v-if="mode == 'edit'">
      <div
        v-show="!loading"
        class="assignment-review"
      >


        <item-skeleton
          v-if="asset"
          :asset="asset"
        />
      </div>
    </div>
    <div v-if="mode == 'preview'">
      <mobile-preview :asset="newAsset" />
    </div>

    <edit-footer v-show="!loading">
      <template v-slot:left>
        <div class="left">

        </div>
      </template>
      <template v-slot:center>
        <div class="center">
          <div class="center-actions" v-if="canViewCopyWriterFooterButtons">
            <button
              class="hlt-button secondary save-assignment-button"
              @click="saveAssignment()"
            >
              Save
            </button>
            <button
              class="hlt-button primary submit-asset-changes save-submit-button"
              @click="saveAndSubmitAssignment()"
              :disabled="saving"
            >
              Submit For Review
            </button>
          </div>
          <div class="center-actions" v-else-if="canViewCopyEditorFooterButtons">
            <button
              class="hlt-button warning revision-button"
              @click="showNeedsRevisionsModal()"
            >
              Needs Revisions
            </button>
            <button
              v-tooltip="{ content: 'You still have pending reviews', trigger: 'manual', show: pendingTooltip }"
              class="hlt-button primary submit-asset-changes complete-review-button"
              @click="showCompleteReviewModal()"
            >
              Complete Review
            </button>
          </div>
        </div>
      </template>
      <template v-slot:right>
        <div class="right">
          <button
            v-if="nextAssignmentId"
            class="hlt-button default"
            @click="nextAsset"
          >
            Next Asset
          </button>
        </div>
      </template>
    </edit-footer>
    <asset-changes-modal />
    <confirmation-modal @confirmed="confirmed" />
    <div class="asset-discussions-section">
      <asset-discussions />
    </div>

  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import TopNav from '../shared/TopNav.vue'
import ItemSkeleton from '../../assets/items/ItemSkeleton.vue'
import AssetChangesModal from './AssetChangesModal.vue'
import EditFooter from '../../assets/shared/page_components/EditFooter.vue'
import ConfirmationModal from '../../shared/ConfirmationModal.vue'
import AssetDiscussions from '../../assets/shared/AssetDiscussions.vue'

import PreviewToggle from '../../assets/shared/PreviewToggle.vue'
import MobilePreview from '../../assets/items/mobile_previews/MultipleChoicePreview.vue'

export default {
  name: 'Review',
  components: {
    TopNav,
    ItemSkeleton,
    EditFooter,
    AssetChangesModal,
    ConfirmationModal,
    AssetDiscussions,
    PreviewToggle,
    MobilePreview
  },
  data () {
    return {
      loading: true,
      pendingTooltip: false,
      saving: false,
      mode: 'edit'
    }
  },
  computed: {
    ...mapGetters([
      'asset',
      'assignment',
      'currentUser',
      'nextAssignmentId'
    ]),
    newAsset () {
      // Apply change requests to the asset
      let tempAsset = {
        question: this.asset.question,
        rationale: this.asset.rationale,
        choices: this.asset.choices
      }

      let questionChange = this.assignment.asset_change_requests.filter(x => x.asset_type == 'V3Flashcard' && x.asset_column == 'question')[0]
      if (questionChange) {
        tempAsset.question = questionChange.requested_change
      }

      let rationaleChange = this.assignment.asset_change_requests.filter(x => x.asset_type == 'V3Flashcard' && x.asset_column == 'rationale')[0]
      if (rationaleChange) {
        tempAsset.rationale = rationaleChange.requested_change
      }

      let choiceChanges = this.assignment.asset_change_requests.filter(x => x.asset_type == 'Answer')
      choiceChanges.forEach(changeRequest => {
        let oldChoice = tempAsset.choices.filter(x => x.id == changeRequest.asset_id)[0]
        if(changeRequest.asset_column == 'raw_content') {
          oldChoice.raw_content = changeRequest.requested_change
        } else if (changeRequest.asset_column == 'raw_rationale') {
          oldChoice.raw_rationale = changeRequest.requested_change
        }
      })

      return tempAsset
    },
    hasPendingRequests () {
      return this.assignment.asset_change_requests.filter(r => r.state === 'pending').length > 0
    },
    canViewCopyWriterFooterButtons () {
      if (this.assignment) {
        if (this.currentUser.permissions.is_a_copy_writer && !this.currentUser.is_admin) {
          return this.assignment.state === 'assigned' || this.assignment.state === 'rejected'
        }
      }
      return false
    },
    canViewCopyEditorFooterButtons () {
      if (this.assignment) {
        if (this.currentUser.permissions.is_a_copy_editor && !this.currentUser.is_admin) {
          return this.assignment.state === 'submitted'
        }
      }
      return false
    }

  },
  created () {
    this.getAssignment(this.$route.params.id).then(() => {
      const record = this.assignment
      this.setAsset({ asset: record.asset })
      this.setAssetType({ assetType: 'item' })
      this.loading = false
    })
  },
  methods: {
    ...mapActions([
      'getAssignment',
      'updateAssignment'
    ]),
    ...mapMutations([
      'setAsset',
      'setAssetType',
      'changeAssignmentState'
    ]),
    confirmed (data) {
      if (data.confirmationName == 'needsRevision') {
        this.needsRevisions()
      } else if (data.confirmationName == 'completeReview') {
        this.completeReview()
      }
    },
    saveAssignment () {
      return this.updateAssignment()
    },
    saveAndSubmitAssignment () {
      this.saving = true
      this.changeAssignmentState('submitted')
      this.saveAssignment().then(() => {
        this.saving = false
      })
    },
    showNeedsRevisionsModal () {
      this.$modal.show('confirmation-modal', {
        title: 'Needs Revisions',
        body: 'Are you sure you want to send this back for revisions?',
        confirmationName: 'needsRevision',
        confirmationStyling: 'review-confirmation-modal'
      })
    },
    showCompleteReviewModal () {
      if (!this.hasPendingRequests) {
        this.$modal.show('confirmation-modal', {
          title: 'Complete Review',
          body: 'After completion, all approved changes will be applied to the live item. <br /> Are you sure you want to complete this asset?',
          confirmationName: 'completeReview',
          confirmationStyling: 'review-confirmation-modal'
        })
      } else {
        this.pendingTooltip = true
        setTimeout(() => {
          this.pendingTooltip = false
        }, 2000)
      }
    },
    needsRevisions () {
      this.changeAssignmentState('rejected')
      this.saveAssignment()
    },
    completeReview () {
      this.changeAssignmentState('completed')
      this.saveAssignment().then(() => {
        this.$router.push({ name: 'asset_assignments' })
      })
    },
    nextAsset () {
      if (this.nextAssignmentId) {
        this.$router.push({ name: 'asset_assignment', params: { id: this.nextAssignmentId } })
      }
    }
  }
}
</script>

<style lang="scss">
.asset-assignment-container {
  width: 100%;

  .toggle-container {
    width: 90%;
    display: flex;
    justify-content: right;
    margin-top: 20px;
  }

  .assignment-review {
    padding: 0rem 12rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 600px;
  }
  .submit-asset-changes {
    margin-left: 20px;
  }
}
.review-confirmation-modal {
  text-align: center;
}
</style>
